import React from "react";
import { MaintenanceTemplate } from "../components/Maintenance";
import { useMaintenance } from "./hooks/useMaintenance";

export const DetectMaintenance: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { isMaintenanceMode, endTime } = useMaintenance();

  if (isMaintenanceMode) {
    return <MaintenanceTemplate endTime={endTime} />;
  }

  return <>{children}</>;
};

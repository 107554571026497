import { useEffect, useState } from "react";

const isProd: boolean = process.env.ENV === "production";
const isLocal: boolean = process.env.ENV === "development";

export const useMaintenance = (): {
  isMaintenanceMode: boolean;
  endTime: string;
} => {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState<boolean>(false);
  const [endTime, setEndTime] = useState<string>("");

  useEffect(() => {
    const _check = () => {
      try {
        // 開発者のアクセス
        if (localStorage.getItem("isYondemyDeveloper") === "1") {
          console.log("skip checking maintenance");
          return;
        }
      } catch (e) {
        console.error(e);
      }

      const randomString: string = Math.random().toString(32).substring(2);
      const url = isLocal
        ? "/maintenance_local.json"
        : isProd
          ? `https://storage.googleapis.com/alnair-prd/maintenance.json?rs=${randomString}`
          : `https://storage.googleapis.com/alnair-dev/maintenance.json?rs=${randomString}`;

      fetch(url)
        .then((res) => {
          res.json().then((json) => {
            setIsMaintenanceMode(json.lp_maintenance === true);
            setEndTime(json.lp_endTime);
          });
        })
        .catch((e) => {
          console.error(e);
        });
    };

    _check(); // 初回

    window.setInterval(() => {
      _check(); // 2回目〜
    }, 30000);
  }, []);

  return { isMaintenanceMode, endTime };
};

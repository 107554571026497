import React from "react";

// ポップアップを再表示する際は、以下のコメントアウトを外してください。
export const PopupHandler: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // const [hasAddState, setHasAddState] = React.useState(false);
  // React.useEffect(() => {
  //   const _pushState = () => {
  //     const ref = document.referrer;
  //     const path = window.location.pathname;
  //     if (!hasAddState && ref.indexOf("yondemy.com") === -1 && path === "/") {
  //       window.history.replaceState({ openPopUp: true }, "", "/");
  //       window.history.pushState({ openPopUp: false }, "", "/");
  //       setHasAddState(true);
  //     }
  //   };
  //   window.addEventListener("click", _pushState);
  //   return () => {
  //     window.removeEventListener("click", _pushState);
  //   };
  // }, [hasAddState]);

  return <>{children}</>;
};

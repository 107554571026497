import React from "react";
import { ApolloProvider, makeVar, useReactiveVar } from "@apollo/client";
import { client } from "./apollo/client";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import { DetectMaintenance } from "./utils/detectMaintenance";
import { GeneralFeatureProvider } from "./contexts/generalFeature";
import "./utils/sentry";
import { PopupHandler } from "./utils/popupHandler";
import { Helmet } from "react-helmet";

declare global {
  interface Window {
    gtag: Gtag.Gtag;
    dataLayer: Array<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  }
}

export const DataContext = React.createContext(null);

const _lastTopPageScrollY = makeVar<number>(0);
const _lastTopHash = makeVar<string>("");
const _lastLocation = makeVar<string>("");

const ResetScroll: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const lastTopPageScrollY = useReactiveVar(_lastTopPageScrollY);
  const lastTopHash = useReactiveVar(_lastTopHash);
  const lastLocation = useReactiveVar(_lastLocation);

  const registerScroll = () => {
    if (location.pathname === "/") {
      _lastTopPageScrollY(document.body.scrollTop);
    }
  };

  React.useEffect(() => {
    document.body.addEventListener("scroll", registerScroll);
    if (location.pathname === "/") {
      _lastTopHash(location.hash);
    }

    return () => {
      document.body.removeEventListener("scroll", registerScroll);
    };
  }, []);

  React.useEffect(() => {
    const hash = location.hash;
    const body = document.getElementsByTagName("body")[0];
    // トップ画面のみスクロール位置を保持
    if ((!hash || hash !== lastTopHash) && location.pathname !== lastLocation) {
      body.scrollTop = location.pathname === "/" ? lastTopPageScrollY : 0;
    }
    if (location.pathname !== lastLocation) {
      _lastLocation(location.pathname);
    }
  });
  return <>{children}</>;
};

// eslint-disable-next-line
export const wrapPageElement = ({ element, props }): React.ReactElement => {
  const { location, pageContext } = props;

  return (
    <>
      <Helmet>
        <meta
          httpEquiv="Cache-Control"
          content="no-cache, no-store, must-revalidate"
        />
        <meta httpEquiv="Pragma" content="no-cache" />
        <meta httpEquiv="Expires" content="0" />
      </Helmet>
      <ApolloProvider client={client}>
        <DataContext.Provider value={{ location, pageContext }}>
          <GeneralFeatureProvider>
            <ThemeProvider theme={theme}>
              <DetectMaintenance>
                <PopupHandler>
                  <ResetScroll>{element}</ResetScroll>
                </PopupHandler>
              </DetectMaintenance>
            </ThemeProvider>
          </GeneralFeatureProvider>
        </DataContext.Provider>
      </ApolloProvider>
    </>
  );
};

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  Time: { input: any; output: any; }
};

export enum AffiliatePlatformType {
  A8 = 'A8',
  Afb = 'AFB',
  Moshimo = 'MOSHIMO'
}

export type AffiliateRegistrationToken = {
  affiliatePlatformType: AffiliatePlatformType;
  tokenString: Scalars['String']['input'];
};

export enum BookLikeability {
  Like = 'LIKE',
  NotLike = 'NOT_LIKE',
  VeryLike = 'VERY_LIKE',
  VeryNotLike = 'VERY_NOT_LIKE'
}

export type CreateTmpParentWithOnboardingEnqueteInput = {
  affiliateRegistrationToken?: InputMaybe<AffiliateRegistrationToken>;
  detailedEnqueteInput?: InputMaybe<DetailedFirstRecommendEnqueteInput>;
  email: Scalars['String']['input'];
  simpleEnqueteInput?: InputMaybe<SimpleFirstRecommendEnqueteInput>;
  userNickname: Scalars['String']['input'];
  utmParams: RegisterTmpEmailLoginParentUtmParam;
};

export type DetailedFirstRecommendEnqueteDifficulties = {
  first: DetailedFirstRecommendEnqueteDifficulty;
  second: DetailedFirstRecommendEnqueteDifficulty;
};

export enum DetailedFirstRecommendEnqueteDifficulty {
  Difficult = 'DIFFICULT',
  Easy = 'EASY',
  Normal = 'NORMAL',
  VeryDifficult = 'VERY_DIFFICULT',
  VeryEasy = 'VERY_EASY'
}

export type DetailedFirstRecommendEnqueteInput = {
  bookLikeability: BookLikeability;
  favoriteBookGenres?: InputMaybe<Array<FavoriteBookGenre>>;
  mostFavoriteBookGenre?: InputMaybe<FavoriteBookGenre>;
  sampleDifficultyPerception: SampleDifficultyPerception;
  suitableYL: Scalars['Int']['input'];
  version: DetailedFirstRecommendEnqueteVersion;
  yl15difficulties?: InputMaybe<DetailedFirstRecommendEnqueteDifficulties>;
  yl20difficulties?: InputMaybe<DetailedFirstRecommendEnqueteDifficulties>;
  yl25difficulties?: InputMaybe<DetailedFirstRecommendEnqueteDifficulties>;
  yl30difficulties?: InputMaybe<DetailedFirstRecommendEnqueteDifficulties>;
  yl35difficulties?: InputMaybe<DetailedFirstRecommendEnqueteDifficulties>;
  yl40difficulties?: InputMaybe<DetailedFirstRecommendEnqueteDifficulties>;
  yl45difficulties?: InputMaybe<DetailedFirstRecommendEnqueteDifficulties>;
  yl50difficulties?: InputMaybe<DetailedFirstRecommendEnqueteDifficulties>;
};

export enum DetailedFirstRecommendEnqueteVersion {
  Version_1 = 'VERSION_1'
}

export enum DifficultyYl {
  Difficult = 'DIFFICULT',
  Easy = 'EASY',
  Normal = 'NORMAL',
  VeryDifficult = 'VERY_DIFFICULT',
  VeryEasy = 'VERY_EASY'
}

export enum FavoriteBookGenre {
  Bouken = 'BOUKEN',
  Doubutsu = 'DOUBUTSU',
  Kandou = 'KANDOU',
  Mahou = 'MAHOU',
  Obake = 'OBAKE',
  Tatakau = 'TATAKAU',
  Waraeru = 'WARAERU'
}

export type Mutation = {
  __typename?: 'Mutation';
  createTmpParentWithOnboardingEnquete: Ok;
};


export type MutationCreateTmpParentWithOnboardingEnqueteArgs = {
  input: CreateTmpParentWithOnboardingEnqueteInput;
};

export type Ok = {
  __typename?: 'OK';
  state: OkState;
};

export enum OkState {
  Success = 'SUCCESS'
}

export type Query = {
  __typename?: 'Query';
  isDuplicateEmail: Scalars['Boolean']['output'];
  isValidUserNickname: Scalars['Boolean']['output'];
  ping: Scalars['String']['output'];
};


export type QueryIsDuplicateEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryIsValidUserNicknameArgs = {
  userNickname: Scalars['String']['input'];
};

export type RegisterTmpEmailLoginParentUtmParam = {
  campaign?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  medium?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export enum SampleDifficultyPerception {
  Difficult = 'DIFFICULT',
  Easy = 'EASY'
}

export type SimpleFirstRecommendEnqueteInput = {
  bookLikeability: BookLikeability;
  difficultyYL15?: InputMaybe<DifficultyYl>;
  difficultyYL20?: InputMaybe<DifficultyYl>;
  difficultyYL25?: InputMaybe<DifficultyYl>;
  difficultyYL30?: InputMaybe<DifficultyYl>;
  difficultyYL35?: InputMaybe<DifficultyYl>;
  difficultyYL40?: InputMaybe<DifficultyYl>;
  difficultyYL45?: InputMaybe<DifficultyYl>;
  difficultyYL50?: InputMaybe<DifficultyYl>;
  favoriteBookGenres?: InputMaybe<Array<FavoriteBookGenre>>;
  mostFavoriteBookGenre?: InputMaybe<FavoriteBookGenre>;
};

export enum StringMatchType {
  Exact = 'EXACT',
  PartialAnd = 'PARTIAL_AND',
  PartialOr = 'PARTIAL_OR'
}

export type StringSearch = {
  strs: Array<Scalars['String']['input']>;
  type?: InputMaybe<StringMatchType>;
};

export enum TimeRange {
  All = 'ALL'
}

export enum UserPlan {
  Basic = 'BASIC',
  Invalidation = 'INVALIDATION',
  Light = 'LIGHT',
  Premium = 'PREMIUM'
}

export type CreateTmpParentWithOnboardingEnqueteMutationVariables = Exact<{
  input: CreateTmpParentWithOnboardingEnqueteInput;
}>;


export type CreateTmpParentWithOnboardingEnqueteMutation = { __typename?: 'Mutation', createTmpParentWithOnboardingEnquete: { __typename?: 'OK', state: OkState } };

export type IsDuplicateEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type IsDuplicateEmailQuery = { __typename?: 'Query', isDuplicateEmail: boolean };

export type IsValidUserNicknameQueryVariables = Exact<{
  userNickname: Scalars['String']['input'];
}>;


export type IsValidUserNicknameQuery = { __typename?: 'Query', isValidUserNickname: boolean };


export const CreateTmpParentWithOnboardingEnqueteDocument = gql`
    mutation CreateTmpParentWithOnboardingEnquete($input: CreateTmpParentWithOnboardingEnqueteInput!) {
  createTmpParentWithOnboardingEnquete(input: $input) {
    state
  }
}
    `;
export type CreateTmpParentWithOnboardingEnqueteMutationFn = Apollo.MutationFunction<CreateTmpParentWithOnboardingEnqueteMutation, CreateTmpParentWithOnboardingEnqueteMutationVariables>;

/**
 * __useCreateTmpParentWithOnboardingEnqueteMutation__
 *
 * To run a mutation, you first call `useCreateTmpParentWithOnboardingEnqueteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTmpParentWithOnboardingEnqueteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTmpParentWithOnboardingEnqueteMutation, { data, loading, error }] = useCreateTmpParentWithOnboardingEnqueteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTmpParentWithOnboardingEnqueteMutation(baseOptions?: Apollo.MutationHookOptions<CreateTmpParentWithOnboardingEnqueteMutation, CreateTmpParentWithOnboardingEnqueteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTmpParentWithOnboardingEnqueteMutation, CreateTmpParentWithOnboardingEnqueteMutationVariables>(CreateTmpParentWithOnboardingEnqueteDocument, options);
      }
export type CreateTmpParentWithOnboardingEnqueteMutationHookResult = ReturnType<typeof useCreateTmpParentWithOnboardingEnqueteMutation>;
export type CreateTmpParentWithOnboardingEnqueteMutationResult = Apollo.MutationResult<CreateTmpParentWithOnboardingEnqueteMutation>;
export type CreateTmpParentWithOnboardingEnqueteMutationOptions = Apollo.BaseMutationOptions<CreateTmpParentWithOnboardingEnqueteMutation, CreateTmpParentWithOnboardingEnqueteMutationVariables>;
export const IsDuplicateEmailDocument = gql`
    query isDuplicateEmail($email: String!) {
  isDuplicateEmail(email: $email)
}
    `;

/**
 * __useIsDuplicateEmailQuery__
 *
 * To run a query within a React component, call `useIsDuplicateEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsDuplicateEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsDuplicateEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useIsDuplicateEmailQuery(baseOptions: Apollo.QueryHookOptions<IsDuplicateEmailQuery, IsDuplicateEmailQueryVariables> & ({ variables: IsDuplicateEmailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsDuplicateEmailQuery, IsDuplicateEmailQueryVariables>(IsDuplicateEmailDocument, options);
      }
export function useIsDuplicateEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsDuplicateEmailQuery, IsDuplicateEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsDuplicateEmailQuery, IsDuplicateEmailQueryVariables>(IsDuplicateEmailDocument, options);
        }
export function useIsDuplicateEmailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IsDuplicateEmailQuery, IsDuplicateEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IsDuplicateEmailQuery, IsDuplicateEmailQueryVariables>(IsDuplicateEmailDocument, options);
        }
export type IsDuplicateEmailQueryHookResult = ReturnType<typeof useIsDuplicateEmailQuery>;
export type IsDuplicateEmailLazyQueryHookResult = ReturnType<typeof useIsDuplicateEmailLazyQuery>;
export type IsDuplicateEmailSuspenseQueryHookResult = ReturnType<typeof useIsDuplicateEmailSuspenseQuery>;
export type IsDuplicateEmailQueryResult = Apollo.QueryResult<IsDuplicateEmailQuery, IsDuplicateEmailQueryVariables>;
export const IsValidUserNicknameDocument = gql`
    query isValidUserNickname($userNickname: String!) {
  isValidUserNickname(userNickname: $userNickname)
}
    `;

/**
 * __useIsValidUserNicknameQuery__
 *
 * To run a query within a React component, call `useIsValidUserNicknameQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsValidUserNicknameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsValidUserNicknameQuery({
 *   variables: {
 *      userNickname: // value for 'userNickname'
 *   },
 * });
 */
export function useIsValidUserNicknameQuery(baseOptions: Apollo.QueryHookOptions<IsValidUserNicknameQuery, IsValidUserNicknameQueryVariables> & ({ variables: IsValidUserNicknameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsValidUserNicknameQuery, IsValidUserNicknameQueryVariables>(IsValidUserNicknameDocument, options);
      }
export function useIsValidUserNicknameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsValidUserNicknameQuery, IsValidUserNicknameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsValidUserNicknameQuery, IsValidUserNicknameQueryVariables>(IsValidUserNicknameDocument, options);
        }
export function useIsValidUserNicknameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IsValidUserNicknameQuery, IsValidUserNicknameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IsValidUserNicknameQuery, IsValidUserNicknameQueryVariables>(IsValidUserNicknameDocument, options);
        }
export type IsValidUserNicknameQueryHookResult = ReturnType<typeof useIsValidUserNicknameQuery>;
export type IsValidUserNicknameLazyQueryHookResult = ReturnType<typeof useIsValidUserNicknameLazyQuery>;
export type IsValidUserNicknameSuspenseQueryHookResult = ReturnType<typeof useIsValidUserNicknameSuspenseQuery>;
export type IsValidUserNicknameQueryResult = Apollo.QueryResult<IsValidUserNicknameQuery, IsValidUserNicknameQueryVariables>;
import React from "react";
import styled from "styled-components";
import {
  ActOnSpecialCommercialTransactionsLink,
  CorpSiteLink,
  PrivacyPolicyLink,
  TermsOfServiceLink,
} from "../../../../util/constant";
import Oops from "./images/oops.png";

export type MaintenanceTemplateProps = {
  endTime: string;
};

export const MaintenanceTemplate: React.FC<MaintenanceTemplateProps> = ({
  endTime,
}) => {
  const year = String(new Date().getFullYear());
  const yearRange = "2020" === year ? year : "2020 - " + year;

  return (
    <>
      <_Wrapper>
        <_TitleWrapper>
          <_Title>ただいまメンテナンス中です</_Title>
        </_TitleWrapper>
        <_ContentWrapper>
          <_ContentContainer>
            <_SenseiImage src={Oops} />
            <_Message>
              {endTime ? `${endTime}まで` : "現在"}メンテナンス中です。
              <br />
              ご迷惑をおかけして申し訳ありません。
            </_Message>
            <_Inquires>
              ご不明点は
              <br />
              cs@yondemy.com
              <br />
              までお問い合わせください
            </_Inquires>
          </_ContentContainer>
        </_ContentWrapper>
      </_Wrapper>
      <_Footer>
        <_Links>
          <_ExternalLink href={CorpSiteLink}>運営会社について</_ExternalLink>
          <_ExternalLink href={TermsOfServiceLink}>利用規約</_ExternalLink>
          <_ExternalLink href={ActOnSpecialCommercialTransactionsLink}>
            特定商取引法に関する表示
          </_ExternalLink>
          <_ExternalLink href={PrivacyPolicyLink}>
            プライバシーポリシー
          </_ExternalLink>
          <div style={{ borderBottom: "2px solid white" }} />
          <_Company>&copy;株式会社Yondemy, {yearRange}</_Company>
        </_Links>
      </_Footer>
    </>
  );
};

const _Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.base.ashGray};
  overflow: hidden;
`;

const _TitleWrapper = styled.div`
  margin: 0 auto;
  padding: 48px 20px;
  width: 100%;
  max-width: 820px;
`;

const _Title = styled.h1`
  @media screen and (min-width: ${({ theme }) =>
      theme.screenSize.smartPhoneMax}) {
    font-size: ${({ theme }) => theme.fontSize.SUPERL};
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.screenSize.smartPhoneMax}) {
    font-size: ${({ theme }) => theme.fontSize.XXXL};
  }
  color: ${({ theme }) => theme.colors.tex.t800};
  line-height: 140%;
  font-weight: 700;
  text-align: left;
`;

const _ContentWrapper = styled.div`
  border-right: 3px solid;
  border-top: 3px solid;
  border-color: ${({ theme }) => theme.colors.base.lightGray};
  border-top-right-radius: 72px;

  padding: 0 20px;
  background-color: ${({ theme }) => theme.colors.base.white};
  margin-right: -3px;
`;

const _ContentContainer = styled.div`
  padding: 40px 0 80px 0;
  margin: 0 auto;
  width: 100%;
  max-width: 320px;

  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
`;

const _SenseiImage = styled.img`
  width: 80px;
  height: 135px;
`;

const _Message = styled.p`
  width: 100%;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSize.MD};
  line-height: 170%;
  color: ${({ theme }) => theme.colors.pri.p600};
  text-align: left;
`;

const _Inquires = styled.p`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.XS};
  line-height: 170%;
  color: ${({ theme }) => theme.colors.tex.t600};
  text-align: left;
`;

const _Footer = styled.footer`
  background-color: ${({ theme }) => theme.colors.sec.s500};
`;

const _Links = styled.div`
  margin: 0 auto;
  padding: 40px 0;
  width: 160px;
  display: flex;
  flex-direction: column;
  row-gap: 13px;
`;

const _ExternalLink = styled.a`
  color: ${({ theme }) => theme.colors.base.white};
  font-size: ${({ theme }) => theme.fontSize.XS};
  display: block;
  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.base.white};
  }
`;

const _Company = styled.p`
  color: ${({ theme }) => theme.colors.base.white};
  font-size: ${({ theme }) => theme.fontSize.XXS};
  text-align: center;
`;

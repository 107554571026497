import config from "../../config";
import * as Sentry from "@sentry/react";
import { getClarityURL } from "./clarity";
import { v4 as uuidv4 } from "uuid";

export const sentryErrorTrackingID = uuidv4();

if (config.SENTRY_DSN) {
  Sentry.init({
    debug: false,
    environment: config.ENV,
    dsn: config.SENTRY_DSN,
    release: config.SENTRY_RELEASE_VERSION,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    denyUrls: [/connect\.facebook\.net\/en_US\/fbevents\.js/i],
  });

  Sentry.setTag("errorTrackingID", sentryErrorTrackingID);
  Sentry.setExtra("clarityURL", getClarityURL(sentryErrorTrackingID));
}

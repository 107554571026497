export const ParentPasswordMaxLength = 32;
export const ParentPasswordMinLength = 8;
export const UserPasswordMaxLength = 32;
export const UserPasswordMinLength = 8;
export const TextMaxLength = 1024;
export const NameMaxLength = 64;
export const NicknameMaxLength = 16;
export const TitleMaxLength = 256;
export const LoginIDMaxLength = 32;
export const LoginIDMinLength = 8;
export const InfoSiteLink = "https://yondemy.wraptas.site";
export const CorpSiteLink = "https://corp-yondemy.wraptas.site/";
export const FaqLink = "https://tayori.com/q/yondemy";
export const TermsOfServiceLink =
  "https://yondemy.wraptas.site/terms_of_service";
export const ActOnSpecialCommercialTransactionsLink =
  "https://yondemy.wraptas.site/commercial_transaction";
export const PrivacyPolicyLink = "https://yondemy.wraptas.site/privacy_policy";
export const BookmarkGuideLink =
  "https://yondemy.wraptas.site/faq#block-c12c3a4fed264110aedb66ba25b0f942";
export const SubscriptionGuideLink =
  "https://yondemy.wraptas.site/subscription_guide";
export const QuitGuideLink = "https://yondemy.wraptas.site/quit";
export const HomeIconAppleLink =
  "https://yondemy.wraptas.site/dd8436c49312433986b1d94199498b3b";
export const HomeIconAndroidLink =
  "https://yondemy.wraptas.site/fff3a51d8b44469c9150012a381f5051";
export const NotificationLineLink = "https://lin.ee/20X0avx";
export const OfficialLineLink = "https://lin.ee/a08ni2T";
export const CSMailAnchorLink = "mailto:cs@yondemy.com";

import fetch from "cross-fetch";
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/link-error";
import * as Sentry from "@sentry/react";
import { sentryErrorTrackingID } from "src/utils/sentry";

const ERROR_TRACKING_ID_HEADER = "X-Error-Tracking-ID";

const httpLink = createHttpLink({
  uri: process.env.API_HOST + "graphql",
  fetch,
  fetchOptions: {
    mode: "cors",
  },
});

const headerLink = setContext(async (request, context) => {
  const headers = context.headers || {};

  if (
    request.query.definitions.some(
      (d) => d.kind === "OperationDefinition" && d.operation === "mutation",
    )
  ) {
    const response = await fetch(process.env.API_HOST + "rap_token", {
      mode: "cors",
      method: "POST",
    });
    if (response.ok && process.env.RAP_TOKEN_HEADER) {
      Object.assign(headers, {
        [process.env.RAP_TOKEN_HEADER]: (await response.json()).rap_token,
      });
    }
  }

  return {
    headers: {
      ...headers,
      [ERROR_TRACKING_ID_HEADER]: sentryErrorTrackingID,
    },
  };
});

const errorLink = onError(
  ({ networkError, graphQLErrors, operation, forward }) => {
    if (networkError) {
      const repeat = operation.getContext().repeat ?? 0;
      if (repeat + 1 < (process.env.REQUEST_REPEAT || 2)) {
        operation.setContext({ repeat: repeat === undefined ? 1 : repeat + 1 });
        return forward(operation);
      } else {
        return;
      }
    }

    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        Sentry.withScope((scope) => {
          scope.setExtra("error", JSON.stringify(err, null, 4));
          scope.setExtra("operationName", operation.operationName);
          scope.setExtra(
            "variables",
            JSON.stringify(operation.variables, null, 4),
          );
          scope.setLevel("error");
          Sentry.captureMessage(
            `GraphQL Error: ${operation.operationName} - ${err.message}`,
          );
        });
        if (err.message == "temporary_error") {
          const repeat = operation.getContext().repeat ?? 0;
          if (repeat + 1 < (process.env.REQUEST_REPEAT || 2)) {
            operation.setContext({
              repeat: repeat === undefined ? 1 : repeat + 1,
            });
            return forward(operation);
          } else {
            return;
          }
        }
      }
    }
  },
);

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([errorLink, headerLink, httpLink]),
});

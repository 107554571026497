const CLARITY_PROJECT_ID = "dz3ilun0io";
const CLARITY_URL = `https://clarity.microsoft.com/projects/view/${CLARITY_PROJECT_ID}/impressions`;

export const getClarityURL = (errorTrackingID: string): string => {
  const startDate = new Date().valueOf() - 1000 * 3600;
  const endDate = new Date().valueOf() + 1000 * 3600;

  if (typeof window !== "undefined") {
    window.setTimeout(() => {
      if (typeof window?.clarity === "function") {
        window.clarity("set", "errorTrackingID", errorTrackingID);
      }
    }, 3000);
  }

  return `${CLARITY_URL}?Variables=errorTrackingID%3A${errorTrackingID}&date=Custom&end=${endDate}&start=${startDate}`;
};
